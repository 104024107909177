import {LostItem} from "../../interfaces/fe16";

export function FE16LostItemsTable(lostItems: Array<LostItem>) {
  let lostItemRows = lostItems.map(lostItem => {
    return (
      <tr>
        <td>{lostItem.name}</td>
        <td>{lostItem.description}</td>
        <td>{lostItem.chapter}</td>
        <td>{lostItem.location}</td>
      </tr>
    )
  });

  return (
    <table className="table is-bordered">
      <thead>
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Chapter Available</th>
        <th>Location</th>
      </tr>
      </thead>
      <tbody>
      {lostItemRows}
      </tbody>
    </table>
  );
}