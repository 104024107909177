import {useEffect, useState} from "react";

import {Character} from "../../interfaces/fe16";
import {fetchFE16Characters} from "../../api/fe16";

import {FE16CharactersTable} from "./characters_table";

export function FE16CharactersView() {
  const [characters, setCharacters] = useState<Character[] | []>([]);

  useEffect(() => {
    fetchFE16Characters().then((result) => {
      setCharacters(result);
    });
  });

  return (
    <div>
      <h1 className="title">Fire Emblem: Three Houses - Characters</h1>

      <FE16CharactersTable characters={characters} />
    </div>
  )
}