import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons'

import {Character} from "../../interfaces/fe16";

export function FE16CharactersTable(characters: any) {
  const characterTableRows = characters.characters.map((character: Character) => {
    return (
      <tr key={character.id}>
        <td>{character.name}</td>
        <td>{character.house}</td>
        <td>{character.birthday}</td>
        <td>
          <Link to={{ pathname: `/fe16/characters/${character.name}` }}>
            <button type="button" className="button is-small is-link">
              <FontAwesomeIcon icon={faLink} />
            </button>
          </Link>
        </td>
      </tr>
    )
  });

  return (
    <div className="table-container">
      <table className="table is-size-7-mobile">
        <thead>
        <tr>
          <th>Name</th>
          <th>House</th>
          <th>Birthday</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {characterTableRows}
        </tbody>
      </table>
    </div>
  )
}