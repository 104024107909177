import {Tea} from "../../interfaces/fe16";

export function FE16TeasTable(teas: Array<Tea>) {
  let teaRows = teas.map(tea => {
    return (
      <tr>
        <td>{tea.name}</td>
        <td>{tea.description}</td>
        <td>{tea.grade}</td>
        <td>{tea.price}</td>
      </tr>
    )
  });

  return (
    <table className="table is-bordered">
      <thead>
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Grade</th>
        <th>Price</th>
      </tr>
      </thead>
      <tbody>
      {teaRows}
      </tbody>
    </table>
  );
}