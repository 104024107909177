import React from "react";

import {TeaTimeFinalComment} from "../../interfaces/fe16";

export function FE16CharactersTable(teaTimeFinalComments: Array<TeaTimeFinalComment>) {
  let finalCommentRows: JSX.Element[] = [];

  let teaTimeFinalCommentsSorted = teaTimeFinalComments.sort((a, b) => a.comment.localeCompare(b.comment));

  teaTimeFinalCommentsSorted.forEach(teaTimeFinalComment => {
    let responses = teaTimeFinalComment.responses.sort((a, b) => a.localeCompare(b));

    let cols = [];

    for (let i = 0; i < 3; i++) {
      if (i <= responses.length) {
        cols.push(
          <td>{responses[i]}</td>
        )
      } else {
        cols.push(
          <td/>
        )
      }
    }

    finalCommentRows.push(
      <tr key={teaTimeFinalComment.comment}>
        <td>{teaTimeFinalComment.comment}</td>
        {cols}
      </tr>
    )
  });

  let finalCommentsSection;

  if (teaTimeFinalCommentsSorted.length) {
    finalCommentsSection = (
      <table className="table is-bordered">
        <thead>
        <tr>
          <th><strong>Final Comment</strong></th>
          <th>Answer 1</th>
          <th>Answer 2</th>
          <th>Answer 3</th>
        </tr>
        </thead>
        <tbody>
        {finalCommentRows}
        </tbody>
      </table>
    )
  }

  return finalCommentsSection;
}