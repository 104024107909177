import React from "react";

import {Props, State, Weapon} from "../../interfaces/fe2";

function getRangeDisplay(rangeValues: number[]): string {
  if (rangeValues[0] === -1) {
    return "∞";
  }

  if (rangeValues.length === 1) {
    if (rangeValues[0] === 0) {
      return '-';
    }

    return rangeValues[0].toString();
  }

  return `${rangeValues[0]}-${rangeValues.slice(-1)}`
}

export class WeaponsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      weapons: props.weapons
    }
  }

  render() {
    const weaponsRows = this.props.weapons.map((weapon: Weapon) => {
      return (
        <tr key={weapon.id}>
          <td>{ weapon.name }</td>
          <td>{ weapon.weight }</td>
          <td>{ weapon.might }</td>
          <td>{ weapon.hit }%</td>
          <td>{ weapon.critical }%</td>
          <td>{ getRangeDisplay(weapon.range) }</td>
          <td>{ weapon.effects }</td>
        </tr>
      )
    });

    return (
      <div className="table-container">
        <table className="table is-size-7-mobile">
          <thead>
          <tr>
            <th className="name-column">Name</th>
            <th className="weight-column">Weight</th>
            <th className="might-column">Might</th>
            <th className="hit-column">Hit</th>
            <th className="critical-column">Critical</th>
            <th className="range-column">Range</th>
            <th className="effects-column">Effects</th>
          </tr>
          </thead>
          <tbody>
            { weaponsRows }
          </tbody>
        </table>
      </div>
    )
  }
}