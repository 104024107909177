import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {fetchFE16CharacterByName} from "../../api/fe16";
import {Character} from "../../interfaces/fe16";

import {FE16CharactersTable} from "./tea_time_final_comments_table";
import {FE16LostItemsTable} from "./lost_items_table";
import {FE16TeasTable} from "./teas_table";

export function FE16CharacterView() {
  // @ts-ignore
  let {characterName} = useParams();

  const [character, setCharacter] = useState<Character | null>(null);

  useEffect(() => {
    fetchFE16CharacterByName(characterName).then((result) => {
      setCharacter(result);
    })
  }, [characterName])

  let teaTimeTopicsSection;

  if (character) {
    let counter = 0;

    let teaTimeTopicsGroupedInFours = [];
    let groupOfFour = [];

    let topicsSorted = character.tea_time_topics.sort();

    for (let i = 0; i < topicsSorted.length; i++) {
      groupOfFour.push(topicsSorted[i]);

      if (counter === 3) {
        teaTimeTopicsGroupedInFours.push([...groupOfFour]);
        groupOfFour = [];
        counter = 0;
      } else {
        counter += 1;
      }
    }

    teaTimeTopicsSection = (
      <table className="table is-bordered">
        <thead>
        <tr>
          <td><strong>Tea Time Topics</strong></td>
          <td/>
          <td/>
          <td/>
        </tr>
        </thead>
        <tbody>
        {teaTimeTopicsGroupedInFours.map((teaTimeTopics, index) => (
          <tr key={index}>
            {teaTimeTopics.map(topic => (
              <td key={topic}>{topic}</td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    )
  } else {
    teaTimeTopicsSection = <div/>;
  }

  let characterSection;

  if (character) {
    characterSection = (
      <div>
        <div>
          <strong>Lost Items</strong>
        </div>
        {FE16LostItemsTable(character.lost_items)}
        <div>
          <strong>Favorite Teas</strong>
        </div>
        <ul>
          {FE16TeasTable(character.favorite_teas)}
        </ul>
        <div>
          {teaTimeTopicsSection}
        </div>
        <div>
          {FE16CharactersTable(character.tea_time_final_comments)}
        </div>
      </div>
    )
  } else {
    characterSection = (
      <div/>
    )
  }

  return (
    <div>
      <h1 className="title">{character?.name}</h1>
      {characterSection}
    </div>
  )
}
