import React from "react";

import {fetchFE1Weapons} from "../../api/fe1";
import {Props, Weapon} from "../../interfaces/fe1";
import {WeaponsTable} from "./weapons_table";

function filterWeaponsByType(weapons: Weapon[], weaponType: string) {
  return weapons.filter((weapon) => weapon.weapon_type === weaponType);
}

export class FE1Weapons extends React.Component<any, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      oneTable: "separate",
      weapons: []
    }
  }

  async componentDidMount() {
    this.setState({
      weapons: await fetchFE1Weapons()
    });
  }

  setOneTableValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      oneTable: event.target.value
    });
  }

  render() {
    let tableSection;

    if (this.state.oneTable === "separate") {
      tableSection = (
        <div>
          <WeaponsTable weapons={filterWeaponsByType(this.state.weapons, "SWORD")}/>
          <WeaponsTable weapons={filterWeaponsByType(this.state.weapons, "LANCE")}/>
          <WeaponsTable weapons={filterWeaponsByType(this.state.weapons, "AXE")}/>
          <WeaponsTable weapons={filterWeaponsByType(this.state.weapons, "BOW")}/>
          <WeaponsTable weapons={filterWeaponsByType(this.state.weapons, "FIREARM")}/>
          <WeaponsTable weapons={filterWeaponsByType(this.state.weapons, "DRAGONSTONE")}/>
          <WeaponsTable weapons={filterWeaponsByType(this.state.weapons, "MAGIC")}/>
          <WeaponsTable weapons={filterWeaponsByType(this.state.weapons, "STAFF")}/>
        </div>
      )
    } else {
      tableSection = (
        <div>
          <WeaponsTable weapons={this.state.weapons}/>
        </div>
      )
    }

    return (
      <div>
        <h1 className="title">Fire Emblem: Shadow Dragon and the Blade of Light - Weapons</h1>

        <div className="control">
          <label className="radio">
            <input type="radio" name="one-table" value="separate" checked={this.state.oneTable === "separate"}
                   onChange={this.setOneTableValue}/>
            Show as separate tables
          </label>
          <label className="radio">
            <input type="radio" name="one-table" value="one" checked={this.state.oneTable === "one"}
                   onChange={this.setOneTableValue}/>
            Show as one table
          </label>
        </div>

        {tableSection}
      </div>
    )
  }
}