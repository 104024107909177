import React from 'react';
import './App.css';

import {About} from "./components/about";
import {Home} from "./components/home";

import {FE1Weapons} from "./components/fe1/weapons_view";
import {FE2Weapons} from "./components/fe2/weapons_view";
import {FE16CharacterView} from "./components/fe16/character_view";
import {FE16CharactersView} from "./components/fe16/characters_view";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  // Used by the navbar in mobile to open the menu.
  const [isActive, setIsActive] = React.useState(false);

  return (
    <Router>
      <div className="App">
        <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              Fire Emblem Companion
            </Link>
            <a
              onClick={() => {
                setIsActive(!isActive)
              }}
              role="button"
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="feCompanionNavbar"
            >
              <span aria-hidden="true"/>
              <span aria-hidden="true"/>
              <span aria-hidden="true"/>
            </a>
          </div>

          <div id="feCompanionNavbar" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
            <div className="navbar-start">
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">
                  FE1
                </a>

                <div className="navbar-dropdown">
                  <Link to="/fe1/weapons" className="navbar-item">
                    Weapons
                  </Link>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">
                  FE2
                </a>

                <div className="navbar-dropdown">
                  <Link to="/fe2/weapons" className="navbar-item">
                    Weapons
                  </Link>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">
                  FE16
                </a>

                <div className="navbar-dropdown">
                  <Link to="/fe16/characters" className="navbar-item">
                    Characters
                  </Link>
                </div>
              </div>
            </div>
            <div className="navbar-end">
              <Link to="/about" className="navbar-item">
                About
              </Link>
            </div>
          </div>
        </nav>
        <section className="section">
          <Switch>
            <Route path="/about">
              <About/>
            </Route>
            <Route path="/fe1/weapons">
              <FE1Weapons/>
            </Route>
            <Route path="/fe2/weapons">
              <FE2Weapons/>
            </Route>
            <Route path="/fe16/characters/:characterName">
              <FE16CharacterView/>
            </Route>
            <Route path="/fe16/characters">
              <FE16CharactersView/>
            </Route>
            <Route path="/">
              <Home/>
            </Route>
          </Switch>
        </section>
      </div>
    </Router>
  );
}

export default App;
