const ROOT_URL = process.env.NODE_ENV === 'production' ? 'https://api.fecompanion.tylerstandridge.com' : '';
const apiBase = '/api/v1';

export async function getRequest(requestURL: string) {
  const response = await fetch(`${ROOT_URL}${apiBase}${requestURL}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return await response.json();
}