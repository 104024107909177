import {Link} from "react-router-dom";

export function Home() {
  return (
    <div>
      <h1 className="title">Home</h1>

      <ul>
        <li>Fire Emblem: Shadow Dragon and the Blade of Light</li>
        <ul>
          <li><Link to="/fe1/weapons">Weapons</Link></li>
        </ul>
        <li>Fire Emblem Gaiden</li>
        <ul>
          <li><Link to="/fe2/weapons">Weapons</Link></li>
        </ul>
        <li>Fire Emblem: Three Houses</li>
        <ul>
          <li><Link to="/fe16/characters">Characters</Link></li>
        </ul>
      </ul>
    </div>
  )
}